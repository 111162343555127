<template>
  <div class="content-main">
      <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'contantMain',
  data () {
    return {}
  }
}
</script>
<style lang="less" scoped>
.content-main {
  width: 100%;
  height: 100%;
}
</style>

